import { AdaEmbed, AdaEmbedConfig } from './types';

declare global {
  interface Window {
    adaEmbed: AdaEmbed;
    adaSettings: Partial<AdaEmbedConfig>;
  }
}

export class AdaChatbotPlugin {
  private static permission = false;
  constructor(perm: boolean, handle?: string) {
    if (perm && handle) {
      AdaChatbotPlugin.permission = perm;
      this.init(handle);
    } else {
      window.console.log('Ada Chatbot has no permission/handle ');
    }
  }

  private static noop() {
    window.console.log('Ada Chatbot script not found');
  }

  private static adaEmbedNoop() {
    return {
      start: () => Promise.resolve(this.noop),
      toggle: () => Promise.resolve(this.noop),
      stop: () => Promise.resolve(this.noop)
    };
  }

  private static get instance() {
    return window.adaEmbed ?? this.adaEmbedNoop();
  }

  static isPermission() {
    return AdaChatbotPlugin.permission;
  }

  static start(
    opts: Partial<AdaEmbedConfig>,
    success = this.noop,
    error = this.noop
  ) {
    this.permission && this.instance.start(opts).then(success, error);
  }

  static stop(success = this.noop, error = this.noop) {
    this.permission && this.instance.stop().then(success, error);
  }

  static toggle(success = this.noop, error = this.noop) {
    this.permission && this.instance.toggle().then(success, error);
  }

  private init(handle: string) {
    // if script already added
    if (document.getElementById('__ada')) {
      return;
    }
    // hide defualt ada button
    this.addStyle('iframe#ada-button-frame{ display: none}');

    const script = document.createElement('script');
    script.setAttribute('id', '__ada');
    script.setAttribute('data-handle', handle);
    script.setAttribute('src', 'https://static.ada.support/embed2.js');
    document.head.appendChild(script);
  }

  private addStyle(content: string) {
    const style = document.createElement('style');
    style.textContent = content;
    document.head.appendChild(style);
  }
}
